/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import url('https://fonts.googleapis.com/css?family=Lato:400,700&display=swap');
@import '@angular/cdk/overlay-prebuilt.css';
@import '@ng-select/ng-select/themes/ant.design.theme.css';

@import './../../assets/styles/lists';
@import './../../assets/styles/forms';
@import './../../assets/styles/modal';
@import './../../assets/styles/helpers';
@import './../../assets/styles/colors';
@import './../../assets/styles/ng-select';

@import '@aaa-mobile/shared-components';


// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

$light-aaa-text: white;
$dark-aaa-text: #15284C;

$aaa-primary: (
  100: #063B91,
  500: #063B91,
  700: #063B91,
  contrast: (
    100: $light-aaa-text,
    500: $light-aaa-text,
    700: $light-aaa-text,
  )
);
$aaa-accent: (
  100: $dark-aaa-text,
  500: $dark-aaa-text,
  700: $dark-aaa-text,
  contrast: (
    100: $light-aaa-text,
    500: $light-aaa-text,
    700: $light-aaa-text,
  )
);

$aaa-primary-dark: (
  100: #fff,
  500: #fff,
  700: #fff,
  contrast: (
    100: $dark-aaa-text,
    500: $dark-aaa-text,
    700: $dark-aaa-text,
  )
);
$aaa-accent-dark: (
  100: #62A5F8,
  500: #62A5F8,
  700: #62A5F8,
  contrast: (
    100: $dark-aaa-text,
    500: $dark-aaa-text,
    700: $dark-aaa-text,
  )
);

//$aaa-app-primary: mat.m2-define-palette($aaa-primary);
//$aaa-app-accent: mat.m2-define-palette($aaa-accent);
//$aaa-darkMode-primary: mat.m2-define-palette($aaa-primary-dark);
//$aaa-darkMode-accent: mat.m2-define-palette($aaa-accent-dark);
//$aaa-warn: mat.m2-define-palette(mat.$red-palette);

//$aaa-app-theme: mat.m2-define-light-theme($aaa-app-primary, $aaa-app-accent, $aaa-warn);
//$aaa-dark-theme: mat.m2-define-dark-theme($aaa-darkMode-primary, $aaa-darkMode-accent, $aaa-warn);

//@include mat.all-component-themes($aaa-app-theme);

//.darkMode {
//  @include mat.all-component-themes($aaa-dark-theme);
//}

.drr-theme, mat-dialog-container {
  --ion-background-color: #{$bgDefault};
  --ion-margin: 16px;
  --ion-padding: 16px;

  @import 'redesign.scss';
  @include aaa-font();
}

.drr-modal {
  --ion-background-color: #{$bgDefault};
}

.grecaptcha-badge {
  display: none;
}
